<template>
  <div>
    <div class="mb-5">
      <b-modal id="external-add-employee-modal" title="Add Information" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="employee.name" v-validate="'required|max:255'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
              <div class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="organization" class="col-form-label col-form-label-sm">Organization <span class="custom-required">*</span></label>
              <input type="text" v-model="employee.organization" v-validate="'required|max:255'" id="organization" name="organization" class="form-control form-control-sm" placeholder="Enter organization"/>
              <div class="invalid-feedback">{{ errors.first('organization') }}</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="phone_no" class="col-form-label col-form-label-sm">Phone No <span class="custom-required">*</span></label>
              <input type="text" v-model="employee.phone_no" v-validate="{ required: true, integer:true,  max: 11, min: 11, regex: /^[0-9]*$/ }"  id="phone_no" name="phone no" class="form-control form-control-sm" placeholder="Enter phone no"/>
              <div class="invalid-feedback">{{ errors.first('phone no') }}</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="addEmployeeLoader"  @click.prevent="save">Add</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('external-add-employee-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'employeeAdd',
  props: ['value'],
  data() {
    return {
      departments: [],
      employee: {
        name: '',
        organization: '',
        department_id: null,
        employee_id: null,
        phone_no: '',
        status: 1,
      },
      validation_errors: {},
      show: false,
      addEmployeeLoader: false,
    }
  },
  mounted() {
    this.getDepartments()
  },
  methods: {
    getDepartments() {
      apiClient.get('api/departments')
        .then(response => {
          this.departments = response.data.departments
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addEmployeeLoader = true
          apiClient.post('api/employees', this.employee).then(response => {
            this.addEmployeeLoader = false
            this.employee = {}
            const employee = response.data.employee
            this.$emit('input', this.value.concat({
              id: employee.id,
              name: employee.name,
              designation: employee.designation,
              organization: employee.organization,
              department_id: employee.department_id,
              employee_id: employee.employee_id,
              phone_no: employee.phone_no,
              department: employee.department,
            }))
            this.$bvModal.hide('external-add-employee-modal')
          }).catch(error => {
            this.addEmployeeLoader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
